import { ActionIcon, Avatar, Badge, Box, Divider, Flex, Grid, Input, Paper, Select, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedState } from "@mantine/hooks";
import { IconCalendar, IconFilterOff, IconSearch } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { transactionApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateFormat, dateTimeFormat } from "../../utils/date";
import { renderName } from "../../utils/render-name";

export const HistoryDetails = () => {
  const breadcrumbs = useBreadcrumb();
  const [Action, setAction] = useState<any>([]);
  const params = useParams();
  const location = useLocation();
  const [filters, setFilter] = React.useState<any>({
    accountId: `${params.id}`,
    inOutType: "ALL",
    type: "",
    trxAccount: "",
    startDate: null,
    endDate: null,
    status: null,
    paymentMethod: null,
  });
  const { paymentMethod, transactionTypes, inOutTypes, currencies, transactionStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const PAY_TRX = usePermission("PAY_TRX");
  const [trxAccountDelay, setTrxAccountDelay] = useDebouncedState<any>(null, 1000);
  const [trxAccount, setTrxAccount] = useState<any>(null);

  const transactionStatusTag = (val: string) => {
    switch (val) {
      case "FAILED": {
        return (
          <Badge color="red" size="md" radius="sm" variant="light">
            {transactionStatus.find((item: IReference) => item.code === val)?.name}
          </Badge>
        );
      }
      case "PENDING": {
        return (
          <Badge color="gray" size="md" radius="sm" variant="light">
            {transactionStatus.find((item: IReference) => item.code === val)?.name}
          </Badge>
        );
      }
      case "SUCCESS": {
        return (
          <Badge color="green" size="md" radius="sm" variant="light">
            {transactionStatus.find((item: IReference) => item.code === val)?.name}
          </Badge>
        );
      }
      default: {
        return (
          <Badge color="blue" size="md" radius="sm" variant="light">
            {val || "N/A"}
          </Badge>
        );
      }
    }
  };

  useEffect(() => {
    setFilter({ ...filters, trxAccountDelay });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trxAccountDelay]);

  const columns = useHeader({
    transactionStatusTag,
    paymentMethod,
    transactionTypes,
    currencies,
    onClick: (key, record) => {
      setAction([key, record]);
      console.log("onChange: ", Action[0]);
    },
  });

  return (
    <PageLayout title="DeHUB гүйлгээний түүх" subTitle="Сонгосон данснаас шилжүүлсэн DeHUB гүйлгээний түүх" breadcrumb={breadcrumbs} extra={[]}>
      {PAY_TRX.hasAccess ? (
        <>
          <Paper p="sm" withBorder>
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Сонгосон дансны дугаар">
                  <Input readOnly value={`${location?.state?.number}, ${location?.state?.name}`} placeholder="Данс, дугаар" />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Гүйлгээний төрөл">
                  <Select
                    value={filters.type}
                    onChange={(e) => {
                      setFilter({ ...filters, type: e });
                    }}
                    clearable
                    placeholder="Сонгох"
                    data={transactionTypes.map((item: IReference, index: number) => {
                      return {
                        label: item.name,
                        value: item.code,
                      };
                    })}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerInput
                  type="range"
                  excludeDate={(e) => {
                    return dateFormat(e) > dateFormat(new Date());
                  }}
                  value={[filters.startDate ?? null, filters.endDate ?? null]}
                  label="Эхлэх / Дуусах огноо:"
                  placeholder="сонгох"
                  leftSection={<IconCalendar size={16} />}
                  onChange={(e) => {
                    setFilter({ ...filters, startDate: e[0] && e[0], endDate: e[1] && e[1] });
                  }}
                  valueFormat="YYYY-MM-DD"
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Төлбөрийн хэрэгсэл">
                  <Select
                    value={filters.paymentMethod}
                    clearable
                    onChange={(e) => {
                      setFilter({ ...filters, paymentMethod: e });
                    }}
                    placeholder="Сонгох"
                    data={paymentMethod.map((item: IReference, index: number) => {
                      return {
                        label: item.name,
                        value: item.code,
                      };
                    })}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Орлого, Зарлага">
                  <Select
                    value={filters.inOutType}
                    onChange={(e) => {
                      setFilter({ ...filters, inOutType: e || "ALL" });
                    }}
                    defaultValue="ALL"
                    clearable
                    placeholder="Сонгох"
                    data={inOutTypes.map((item: IReference, index: number) => {
                      return {
                        label: item.name,
                        value: item.code,
                      };
                    })}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Flex w="100%" gap="xs" align="flex-end">
                  <Box w={"100%"}>
                    <Input.Wrapper label="Харьцсан данс сонгох">
                      <Input
                        value={trxAccount}
                        type="number"
                        defaultValue={trxAccount}
                        onChange={(event) => {
                          setTrxAccount(event.currentTarget.value);
                          setTrxAccountDelay(event.currentTarget.value);
                        }}
                        leftSection={<IconSearch size={16} />}
                        placeholder="Хайх"
                      />
                    </Input.Wrapper>
                  </Box>
                  <div>
                    <Input.Wrapper label=" ">
                      <ActionIcon
                        size={36}
                        hidden={!PAY_TRX.hasAccess}
                        onClick={() => {
                          setTrxAccount("");
                          setFilter({
                            accountId: `${params.id}`,
                            inOutType: "ALL",
                            type: "",
                            trxAccount: "",
                            startDate: null,
                            endDate: null,
                            status: null,
                            paymentMethod: null,
                          });
                        }}
                        variant="filled"
                        color="primary">
                        <IconFilterOff />
                      </ActionIcon>
                    </Input.Wrapper>
                  </div>
                </Flex>
              </Grid.Col>
            </Grid>
          </Paper>
          <Paper p="sm" withBorder mt="sm">
            <Divider h="lg" mt="sm" />
            <Table name="account.transaction.list" columns={columns} filters={{ ...filters }} loadData={(data) => transactionApi.list(data!)} />
          </Paper>
        </>
      ) : (
        PAY_TRX.accessDenied()
      )}
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  transactionStatusTag,
  paymentMethod,
  transactionTypes,
  currencies,
}: {
  onClick: (key: string, record: any) => void;
  paymentMethod: IReference[];
  transactionTypes: IReference[];
  transactionStatusTag: (key: string) => JSX.Element;
  currencies: IReference[];
}): ColumnType<any>[] => [
  {
    title: "",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Огноо, цаг",
    dataIndex: "image",
    render: (record) => (
      <Text size="sm" w="max-content">
        {dateTimeFormat(record?.createdAt)}
      </Text>
    ),
  },
  {
    title: "Лавлах код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Гүйлгээний төрөл",
    sorter: true,
    dataIndex: "paymentMethod",
    render: (record) => (
      <Text size="sm" w="max-content">
        {paymentMethod.find((item: IReference) => item.code === record?.paymentMethod)?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн хэрэгсэл",
    sorter: true,
    dataIndex: "type",
    render: (record) => `${transactionTypes.find((item: IReference) => item.code === record?.type)?.name || "-"}`,
  },
  {
    title: "Гүйлгээний дүн",
    sorter: true,
    dataIndex: "unit",
    render: (record) => (
      <Text size="sm" c={record?.inOutType === "CREDIT" ? "red" : "green"} style={{ whiteSpace: "nowrap" }} ta="end">
        {currencyFormat(record?.totalAmount)}
      </Text>
    ),
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "debitAccountCurrency",
    render: (record) => <Text size="sm">{currencies.find((item: IReference) => item.code === record?.debitAccountCurrency)?.name ?? "-"}</Text>,
  },
  {
    title: "Гүйлгээний утга",
    sorter: true,
    dataIndex: "description",
    render: (record) => <Text size="sm">{record?.description ?? "-"}</Text>,
  },
  {
    title: "Гүйлгээний статус",
    sorter: true,
    dataIndex: "description",
    render: (record) => transactionStatusTag(record?.transactionStatus),
  },
  {
    title: "Гүйлгээ хийсэн",
    sorter: true,
    render: (record) => {
      return record?.payerUser ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.payerUser?.avatar} />
          <Text size="sm" w="max-content">
            {renderName(record?.payerUser)}
          </Text>
        </Flex>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Харьцсан дансны дугаар",
    sorter: true,
    render: (record) => <Text size="sm">{record?.debitAccountNumber}</Text>,
  },
  {
    title: "Харьцсан дансны нэр",
    sorter: true,
    render: (record) => <Text size="sm">{record?.debitAccountName ?? "-"}</Text>,
  },
  {
    title: "Харьцсан дансны банк",
    sorter: true,
    render: (record) => <Text size="sm">{record?.debitAccountBank}</Text>,
  },
];

const useBreadcrumb = () => [
  {
    to: "#",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Төлбөр тооцоо",
  },
  {
    label: "DeHUB гүйлгээний түүх",
    to: "/history",
  },
  {
    label: "Дэлгэрэнгүй",
  },
];
